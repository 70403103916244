import { useState } from 'react';
import styles from './MultichainSection.module.scss'
import copyImg from '../../../images/document.svg'
import metamaskImg from '../../../images/metamask.svg'
import shidenBannerPc from '../../../images/shiden.svg'
import polygonBannerPc from '../../../images/polygon.svg'
import ethereumBannerPc from '../../../images/ethereum.svg'
import gnosisBannerPc from '../../../images/gnosis.svg'
import avalancheBannerPc from '../../../images/avalanche.svg'
import astarBannerPc from '../../../images/astar.svg'
import { IconButton, Tooltip } from "@mui/material";

interface Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ethereum: any;
  location: any;
  open(url?: string, target?: string, features?: string): Window | null;
}

declare const window: Window;

type ChainType = 'ethereum' | 'polygon' | 'gnosis' | 'shiden' | 'avalanche' | 'astar';

interface ChainInfo {
  name: string;
  address: string;
  chainId: string;
}

const chainInfo: Record<ChainType, ChainInfo> = {
  ethereum: { name: 'Ethereum', address: '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB', chainId: '0x1' },
  polygon: { name: 'Polygon', address: '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB', chainId: '0x89' },
  gnosis: { name: 'Gnosis', address: '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB', chainId: '0x64' },
  shiden: { name: 'Shiden', address: '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB', chainId: '0x150' },
  avalanche: { name: 'Avalanche', address: '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB', chainId: '0xa86a' },
  astar: { name: 'Astar', address: '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB', chainId: '0x250' }
};

const tokenInfo = {
  decimals: 18,
  image: "static/media/JPYCPrepaid.png",
  symbol: "JPYC"
};

const explorerUrls: Record<ChainType, string> = {
  ethereum: 'https://etherscan.io/address/',
  polygon: 'https://polygonscan.com/address/',
  gnosis: 'https://gnosisscan.io/address/',
  shiden: 'https://shiden.subscan.io/account/',
  avalanche: 'https://snowtrace.io/address/',
  astar: 'https://astar.subscan.io/account/',
};

const MultichainSection = () => {
  const [openTips, setOpenTips] = useState<Record<ChainType, boolean>>({
    ethereum: false,
    polygon: false,
    gnosis: false,
    shiden: false,
    avalanche: false,
    astar: false
  });

  const addToken = async (tokenAddress: string, chainId: string) => {
    if (!window.ethereum) return;

    try {
      if (window.ethereum.chainId !== chainId) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
      }

      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenInfo.symbol,
            decimals: tokenInfo.decimals,
            image: tokenInfo.image,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const copy = (text: string) => {
    navigator.clipboard.writeText(text);
  }

  const openExplorer = (address: string, chain: ChainType) => {
    window.open(explorerUrls[chain] + address, '_blank');
  }

  const handleCopy = (chain: ChainType, address: string) => {
    setOpenTips(prev => ({ ...prev, [chain]: true }));
    copy(address);
    setTimeout(() => setOpenTips(prev => ({ ...prev, [chain]: false })), 1500);
  };

  return (
    <section className={styles.multichain}>
      <div className={styles.multichainWrapper}>
      <h3 className={styles.multichainText}>Multi-chain</h3>
      <h2 className={styles.multichainTitle}>対応チェーン</h2>
        <div className={styles.chainList}>
          {(Object.entries(chainInfo) as [ChainType, ChainInfo][]).map(([chain, info]) => (
            <div key={chain} className={styles.chainWrapper}>
              <h4>{info.name}</h4>
              <p>コントラクトアドレス</p>
              <div className={styles.chainAddress}>
                <p 
                  className={styles.contractAddress}
                  onClick={() => openExplorer(info.address, chain)}
                  style={{ cursor: 'pointer' }}
                >
                  {info.address}
                </p>
                <Tooltip 
                  disableHoverListener   
                  title="Copied!" 
                  open={openTips[chain]} 
                  onClose={() => setOpenTips(prev => ({ ...prev, [chain]: false }))}
                >
                  <IconButton onClick={() => handleCopy(chain, info.address)}>
                    <img src={copyImg} alt="copy"/>
                  </IconButton>
                </Tooltip>
                <img onClick={() => addToken(info.address, info.chainId)} src={metamaskImg} alt="metamask-img" style={{ cursor: 'pointer' }}/>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.coinBannerWrapper}>
          <div className={styles.bannerBox}>
            <img className={styles.shidenBanner} src={shidenBannerPc} alt="shiden-banner" />
          </div>
          <div className={styles.bannerBox}>
            <img className={styles.polygonBanner} src={polygonBannerPc} alt="polygon-banner" />
          </div>
          <div className={styles.bannerBox}>
            <img className={styles.avalancheBanner} src={avalancheBannerPc} alt="avalanche-banner" />
          </div>
          <div className={styles.bannerBox}>
            <img className={styles.ethereumBanner} src={ethereumBannerPc} alt="ethereum-banner" />
          </div>
          <div className={styles.bannerBox}>
            <img className={styles.gnosisBanner} src={gnosisBannerPc} alt="gnosis-banner" />
          </div>
          <div className={styles.bannerBox}>
            <img className={styles.astarBanner} src={astarBannerPc} alt="astar-banner" />
          </div>
        </div>
    </section>
  )
};

export default MultichainSection;
